.Questionnaires-module__Questionnaires__MU7eF {
  position: relative;
}

.Questionnaires-module__Header__YEiXr {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  flex-wrap: wrap;
}

.Questionnaires-module__Footer__T3EgJ {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
