.AppNavigationDrawer-module__NavigationDrawerDialog__ReVOo {
  block-size: 100dvb;
  background-color: transparent;
  border: none;
  inline-size: -moz-max-content;
  inline-size: max-content;
  margin-inline-start: 0;
  inset-inline-start: 0;
}

.AppNavigationDrawer-module__NavigationDrawerDialog__ReVOo > section {
  display: flex;
  flex-direction: column;
  block-size: 100dvb;
  background-color: white;
  border-inline-end: black solid 1px;
}

@container body (min-width: 600px) {
  .AppNavigationDrawer-module__NavigationDrawer__cljyu {
    display: none;
  }
}
