*:where(
    :not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)
  ) {
  all: unset;
  display: revert;
  cursor: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:where(table) {
  border-collapse: collapse;
}

:where(textarea) {
  white-space: revert;
}

:where(meter) {
  -webkit-appearance: revert;
  appearance: revert;
}

:where(pre) {
  all: revert;
}

:where(::placeholder) {
  color: unset;
}

:where(::marker) {
  content: initial;
}

:where(ul, ol) {
  list-style: none;
}

:where(dialog:modal) {
  all: revert;
  margin: auto;
  padding: 0;
  max-inline-size: 100dvi;
  max-block-size: 100dvb;
}

:where([hidden]:not([hidden='until-found'])) {
  display: none !important;
}

:where(html) {
  -webkit-text-size-adjust: none; /* https://kilianvalkhof.com/2022/css-html/your-css-reset-needs-text-size-adjust-probably/ */
}

@media (prefers-reduced-motion: no-preference) {
  :where(html:focus-within) {
    scroll-behavior: smooth;
  }
}

:where(body) {
  block-size: 100%;
  block-size: 100dvb;
  line-height: 1;
  font-family: system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
}

:where(input, button, textarea, select) {
  font: inherit;
  color: inherit;
}

:where(textarea) {
  resize: none;
}

:where(button, label, select, summary, [role='button'], [role='option']) {
  cursor: pointer;
}

:where(label:has(> input:disabled), label:has(+ input:disabled)) {
  cursor: not-allowed;
}

:where(:disabled) {
  cursor: not-allowed;
}

:where(a) {
  text-underline-offset: 0.2ex;
}

:where(img, svg, video, canvas, audio, iframe, embed, object) {
  display: block;
}

:where(img, picture, svg) {
  max-inline-size: 100%;
  block-size: auto;
}

:where(p, h1, h2, h3, h4, h5, h6) {
  overflow-wrap: break-word;
}

:where(:focus-visible) {
  outline: 2px solid var(--focus-color, Highlight);
  outline-offset: 2px;
}

:where(
    .visually-hidden:not(:focus, :active, :focus-within, .not-visually-hidden)
  ) {
  clip-path: inset(50%) !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap !important;
  border: 0 !important;
}
