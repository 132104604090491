.GoogleMapLocationIdComponent-module__Dialog__ysVNF {
  inline-size: 80cqi;
  block-size: 80cqb;
  background-color: transparent;
  border: none;
}

.GoogleMapLocationIdComponent-module__Dialog__ysVNF::backdrop {
  background: hsl(247 21% 17% / 0.5);
}

.GoogleMapLocationIdComponent-module__DialogContent__eKe5l {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: hsl(247 21% 17%);
  inline-size: 100%;
  block-size: 100%;
  background-color: white;
  border: none;
  padding: 16px;
  & > header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    & > button {
      align-self: flex-end;
    }
    & > h1 {
      font-size: 24px;
      font-weight: 600;
    }
  }
  & > main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

.GoogleMapLocationIdComponent-module__Map__aQaTJ {
  inline-size: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  border: 0;
}

.GoogleMapLocationIdComponent-module__AutoComplete__tqllk {
  max-block-size: 10rem;
  block-size: auto;
}
