.LocationContainer-module__LocationContainer__cCZBW {
  block-size: 100cqb;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: clip;
  background-color: hsl(225 22% 96%);
  & > main {
    flex-grow: 1;
    block-size: 100%;
    overflow: clip;
  }
}
