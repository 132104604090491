.Activities-module__Tabs__KmYJY {
  display: flex;
  gap: 12px;
  & .Activities-module__Active__K7-kY {
    border-block-end: 1px solid black;
  }
}

.Activities-module__ActivityListItem__LqXZz {
  & .Activities-module__Avatar__krfng {
    inline-size: 64px;
    aspect-ratio: 1;
    border-radius: 50%;
  }
}
