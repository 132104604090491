:root {
  --white-hsl-raw: 0 0% 100%;
  --white: hsl(var(--white-hsl-raw) / 100%);
  --white-40: hsl(var(--white-hsl-raw) / 40%);
  --light: hsl(225 22% 96% / 100%);
  --dark: hsl(271 55% 10% / 100%);
  --purple: hsl(252 58% 58% / 100%);
  --blue: hsl(230 67% 54% / 100%);
  --yellow: hsl(47 100% 69% / 100%);
  --gray2: hsl(223 8% 72% / 100%);

  --primary-color: var(--purple);
  --primary-button-color: var(--white);
  --primary-button-background-color: var(--primary-color);
  --disabled-button-color: var(--white-40);
  --disabled-button-background-color: var(--gray2);
}

.IconButton {
  font-family: inherit;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  inline-size: calc(24px + 12px);
  block-size: calc(24px + 12px);
  border: 1px solid black;
  border-radius: 100%;
  user-select: none;
  aspect-ratio: 1/1;
  background-color: #eee;
}

.capitalize {
  text-transform: capitalize;
}

label {
  text-transform: capitalize;
  & > *:not(:where(button, input[type='button'], input[type='submit'])) {
    text-transform: revert;
  }
}

* {
  &[data-capitalize='true'] {
    text-transform: capitalize;
  }
  &[data-block-radius='start'] {
    border-top-left-radius: var(--radius-size);
    border-top-right-radius: var(--radius-size);
  }
  &[data-block-radius='end'] {
    border-bottom-left-radius: var(--radius-size);
    border-bottom-right-radius: var(--radius-size);
  }
  &[data-block-radius='both'] {
    border-start-start-radius: var(--radius-size);
    border-start-end-radius: var(--radius-size);
    border-end-start-radius: var(--radius-size);
    border-end-end-radius: var(--radius-size);
  }
  &[data-inline-radius='start'] {
    border-start-start-radius: var(--radius-size);
    border-end-start-radius: var(--radius-size);
  }
  &[data-inline-radius='end'] {
    border-start-end-radius: var(--radius-size);
    border-end-end-radius: var(--radius-size);
  }
  &[data-inline-radius='both'] {
    border-start-start-radius: var(--radius-size);
    border-end-start-radius: var(--radius-size);
    border-start-end-radius: var(--radius-size);
    border-end-end-radius: var(--radius-size);
  }
}
