.Card-module__Card__UdGjd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;

  border-radius: 16px;
  background: hsl(0 0% 100%);
}
