.User-module__User__XzVVz {
  position: relative;
}

.User-module__Header__ytTgn {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  flex-wrap: wrap;
}

.User-module__Tabs__p55oi {
  & > ul {
    display: flex;
    gap: 12px;
  }
}

.User-module__Content__OlhKn {
  padding: 24px;
}

.User-module__Avatar__fJ0B3 {
  inline-size: 64px;
  aspect-ratio: 1;
  border-radius: 50%;
}

.User-module__Name__gDmqE {
  font-size: 24px;
  font-weight: 600;
}
