/* css-modules-plugin-ns:app/components/Pagination.module.css?css-modules-plugin-compiled-css */
.Pagination-module__Pagination__8AEjq {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

/* css-modules-plugin-ns:app/components/ScrollView.module.css?css-modules-plugin-compiled-css */
.ScrollView-module__ScrollAreaRoot__L0Dd4 {
  inline-size: 100%;
  block-size: 100%;
}
.ScrollView-module__ScrollAreaViewport__R9XUI {
  inline-size: 100%;
  block-size: 100%;
}

/* css-modules-plugin-ns:app/routes/$location._c.coupons_.$coupon.send/CouponSend.module.css?css-modules-plugin-compiled-css */
.CouponSend-module__CouponSend__J8oM3 {
  position: relative;
}
.CouponSend-module__Header__QSwqP {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  flex-wrap: wrap;
}
.CouponSend-module__Footer__xF92B {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* css-modules-plugin-ns:app/routes/$location._c.questionnaires/Questionnaires.module.css?css-modules-plugin-compiled-css */
.Questionnaires-module__Questionnaires__MU7eF {
  position: relative;
}
.Questionnaires-module__Header__YEiXr {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  flex-wrap: wrap;
}
.Questionnaires-module__Footer__T3EgJ {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* css-modules-plugin-ns:app/routes/$location._c.users_.$user/User.module.css?css-modules-plugin-compiled-css */
.User-module__User__XzVVz {
  position: relative;
}
.User-module__Header__ytTgn {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  flex-wrap: wrap;
}
.User-module__Tabs__p55oi {
  & > ul {
    display: flex;
    gap: 12px;
  }
}
.User-module__Content__OlhKn {
  padding: 24px;
}
.User-module__Avatar__fJ0B3 {
  inline-size: 64px;
  aspect-ratio: 1;
  border-radius: 50%;
}
.User-module__Name__gDmqE {
  font-size: 24px;
  font-weight: 600;
}

/* css-modules-plugin-ns:app/routes/$location._c.activities/Activities.module.css?css-modules-plugin-compiled-css */
.Activities-module__Tabs__KmYJY {
  display: flex;
  gap: 12px;
  & .Activities-module__Active__K7-kY {
    border-block-end: 1px solid black;
  }
}
.Activities-module__ActivityListItem__LqXZz {
  & .Activities-module__Avatar__krfng {
    inline-size: 64px;
    aspect-ratio: 1;
    border-radius: 50%;
  }
}

/* css-modules-plugin-ns:app/routes/$location._c.campaigns/Campaigns.module.css?css-modules-plugin-compiled-css */
.Campaigns-module__Campaigns__7qQ4K {
  position: relative;
}
.Campaigns-module__Header__6lX-d {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}
.Campaigns-module__Footer__gBCOQ {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Campaigns-module__ToggleGroup__hPp6n {
  display: flex;
  gap: 12px;
}
.Campaigns-module__ToggleGroupItem__9xj7N {
  display: flex;
  gap: 4px;
}

/* css-modules-plugin-ns:app/components/Card.module.css?css-modules-plugin-compiled-css */
.Card-module__Card__UdGjd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  border-radius: 16px;
  background: hsl(0 0% 100%);
}

/* css-modules-plugin-ns:app/routes/$location._c.location/GoogleMapLocationIdComponent.module.css?css-modules-plugin-compiled-css */
.GoogleMapLocationIdComponent-module__Dialog__ysVNF {
  inline-size: 80cqi;
  block-size: 80cqb;
  background-color: transparent;
  border: none;
}
.GoogleMapLocationIdComponent-module__Dialog__ysVNF::backdrop {
  background: hsl(247 21% 17% / 0.5);
}
.GoogleMapLocationIdComponent-module__DialogContent__eKe5l {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: hsl(247 21% 17%);
  inline-size: 100%;
  block-size: 100%;
  background-color: white;
  border: none;
  padding: 16px;
  & > header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    & > button {
      align-self: flex-end;
    }
    & > h1 {
      font-size: 24px;
      font-weight: 600;
    }
  }
  & > main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}
.GoogleMapLocationIdComponent-module__Map__aQaTJ {
  inline-size: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  border: 0;
}
.GoogleMapLocationIdComponent-module__AutoComplete__tqllk {
  max-block-size: 10rem;
  block-size: auto;
}

/* css-modules-plugin-ns:app/routes/$location._c.location/Location.module.css?css-modules-plugin-compiled-css */
.Location-module__Location__LyV96 {
  grid-template-columns: 1fr;
  place-items: stretch stretch;
  gap: 16px;
  padding: 24px;
  & h1 {
    color: hsl(247 21% 17%);
    font-size: 14px;
  }
}
@container body (min-width: 600px) {
  .Location-module__Location__LyV96 {
    grid-template-columns: 1fr 1fr;
  }
  .Location-module__Card__-cYAG:nth-child(odd) {
    grid-column: 1;
  }
  .Location-module__Card__-cYAG:nth-child(even) {
    grid-column: 2;
  }
  .Location-module__Card__-cYAG:nth-child(1) {
    grid-row: span 10;
  }
  .Location-module__Card__-cYAG:nth-child(3) {
    grid-row-end: span 4;
  }
  .Location-module__Card__-cYAG:nth-child(5) {
    grid-row: span 4;
  }
  .Location-module__Card__-cYAG:nth-child(7) {
    grid-row: span 8;
  }
  .Location-module__Card__-cYAG:nth-child(2) {
    grid-row: 1 / span 8;
  }
  .Location-module__Card__-cYAG:nth-child(4) {
    grid-row: 8 / span 4;
  }
  .Location-module__Card__-cYAG:nth-child(6) {
    grid-row: 12 / span 8;
  }
}
.Location-module__Card__-cYAG {
  inline-size: 100%;
}
.Location-module__PreviewButton__VqdLE {
  display: block;
  position: fixed;
  inset-inline-end: 0;
  inset-block-end: 50%;
  z-index: 2;
  border: 1px solid black;
  padding: 8px 16px;
  transform-origin: bottom right;
  transform: rotate(-90deg) translate(50%);
}

/* css-modules-plugin-ns:app/routes/$location._c.settings/Settings.module.css?css-modules-plugin-compiled-css */
.Settings-module__Settings__aSEcL {
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
.Settings-module__C1__vbAcX {
  grid-column: 1;
}
.Settings-module__C2__28twW {
  grid-column: 2;
}
.Settings-module__R1__N6JGm {
  grid-row-end: span 1;
}
.Settings-module__R2__Ev1Yr {
  grid-row-end: span 2;
}
.Settings-module__R3__-ZCBL {
  grid-row-end: span 3;
}
.Settings-module__R4__mLIym {
  grid-row-end: span 4;
}
.Settings-module__R5__xuqiP {
  grid-row-end: span 5;
}
.Settings-module__R6__-zrJl {
  grid-row-end: span 6;
}
.Settings-module__R7__AASrm {
  grid-row-end: span 7;
}
.Settings-module__R8__pVr75 {
  grid-row-end: span 8;
}
.Settings-module__R9__z2fCO {
  grid-row-end: span 9;
}
.Settings-module__R10__pVCM5 {
  grid-row-end: span 10;
}
.Settings-module__RS1__M86Q2 {
  grid-row-start: 1;
}
.Settings-module__RS2__xIpb3 {
  grid-row-start: 2;
}
.Settings-module__RS3__-BKgt {
  grid-row-start: 3;
}
.Settings-module__RS4__f2tvQ {
  grid-row-start: 4;
}
.Settings-module__RS5__ssu11 {
  grid-row-start: 5;
}
.Settings-module__RS6__0VFwY {
  grid-row-start: 6;
}
.Settings-module__RS7__H-wHI {
  grid-row-start: 7;
}
.Settings-module__RS8__P4g4y {
  grid-row-start: 8;
}
.Settings-module__RS9__Hy3Sm {
  grid-row-start: 9;
}
.Settings-module__RS10__yeUkS {
  grid-row-start: 10;
}
.Settings-module__RS11__ksxcx {
  grid-row-start: 11;
}
.Settings-module__RS12__7wh-w {
  grid-row-start: 12;
}
.Settings-module__RS13__69uQa {
  grid-row-start: 13;
}
.Settings-module__RS14__6lEPS {
  grid-row-start: 14;
}
.Settings-module__RS15__h7-Vp {
  grid-row-start: 15;
}
.Settings-module__RS16__zq-y3 {
  grid-row-start: 16;
}
.Settings-module__RS17__LEz8s {
  grid-row-start: 17;
}
.Settings-module__RS18__DhtW6 {
  grid-row-start: 18;
}
.Settings-module__RS19__SjPwp {
  grid-row-start: 19;
}
.Settings-module__RS20__3gvAr {
  grid-row-start: 20;
}

/* css-modules-plugin-ns:app/routes/$location._c.coupons/Coupons.module.css?css-modules-plugin-compiled-css */
.Coupons-module__Coupons__MXq0Z {
  position: relative;
}
.Coupons-module__Header__8yuND {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}
.Coupons-module__Footer__wxOL- {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Coupons-module__ToggleGroup__qRWUo {
  display: flex;
  gap: 12px;
}
.Coupons-module__ToggleGroupItem__VCaji {
  display: flex;
  gap: 4px;
}

/* css-modules-plugin-ns:app/routes/$location._c.events/Events.module.css?css-modules-plugin-compiled-css */
.Events-module__Events__mjsOZ {
  position: relative;
}
.Events-module__Header__mvjr- {
  display: flex;
  justify-content: space-between;
}
.Events-module__Tabs__grXvG {
  display: flex;
  gap: 12px;
  & .Events-module__Active__gfM6b {
    border-block-end: 1px solid black;
  }
}
.Events-module__Content__5zqt- {
  display: flex;
  flex-wrap: wrap;
}

/* css-modules-plugin-ns:app/routes/$location._c.topics/Topics.module.css?css-modules-plugin-compiled-css */
.Topics-module__Topics__icBMm {
  position: relative;
}
.Topics-module__Header__jvh6M {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}
.Topics-module__Footer__P-HA2 {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Topics-module__ToggleGroup__9RM5M {
  display: flex;
  gap: 12px;
}
.Topics-module__ToggleGroupItem__GeC58 {
  display: flex;
  gap: 4px;
}

/* css-modules-plugin-ns:app/routes/$location._c.users/Users.module.css?css-modules-plugin-compiled-css */
.Users-module__Users__yMACD {
  position: relative;
}
.Users-module__Header__QIiGJ {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
  & > div {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
}
.Users-module__Footer__E2gnM {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* css-modules-plugin-ns:app/routes/$location._c.faqs/Faqs.module.css?css-modules-plugin-compiled-css */
.Faqs-module__Faqs__1JptQ {
  position: relative;
}
.Faqs-module__Header__ST6LJ {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  flex-wrap: wrap;
}
.Faqs-module__Footer__EmaIq {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Faqs-module__ToggleGroup__Dm-Oy {
  display: flex;
  gap: 12px;
}
.Faqs-module__ToggleGroupItem__lAVr7 {
  display: flex;
  gap: 4px;
}

/* css-modules-plugin-ns:app/routes/$location._c/AppHeader.module.css?css-modules-plugin-compiled-css */
.AppHeader-module__AppHeader__D86OP {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  inline-size: 100%;
  border-bottom: 1px solid black;
  & .AppHeader-module__Heading__9LlRF {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }
}
.AppHeader-module__HeaderItem__P1R2Y {
  display: flex;
}
.AppHeader-module__HeaderMenu__p1RyT {
  position: relative;
}
.AppHeader-module__HeaderMenuViewPort__Evjkb {
  position: absolute;
}

/* css-modules-plugin-ns:app/routes/$location._c/AppNavigationDrawer.module.css?css-modules-plugin-compiled-css */
.AppNavigationDrawer-module__NavigationDrawerDialog__ReVOo {
  block-size: 100dvb;
  background-color: transparent;
  border: none;
  inline-size: -moz-max-content;
  inline-size: max-content;
  margin-inline-start: 0;
  inset-inline-start: 0;
}
.AppNavigationDrawer-module__NavigationDrawerDialog__ReVOo > section {
  display: flex;
  flex-direction: column;
  block-size: 100dvb;
  background-color: white;
  border-inline-end: black solid 1px;
}
@container body (min-width: 600px) {
  .AppNavigationDrawer-module__NavigationDrawer__cljyu {
    display: none;
  }
}

/* css-modules-plugin-ns:app/routes/$location._c/AppNavigationMenu.module.css?css-modules-plugin-compiled-css */
.AppNavigationMenu-module__NavigationMenu__cS-0Z {
  display: flex;
}

/* css-modules-plugin-ns:app/routes/$location._c/LinkItem.module.css?css-modules-plugin-compiled-css */
.LinkItem-module__Link__LoiTE {
  &[data-active] {
    color: red;
  }
}

/* css-modules-plugin-ns:app/routes/$location._c/AppSideBar.module.css?css-modules-plugin-compiled-css */
.AppSideBar-module__AppSideBar__WJYzo {
  display: none;
  position: relative;
  border-inline-end: black solid 1px;
  block-size: 100dvb;
}
.AppSideBar-module__MinimizeButton__joz3m {
  z-index: 1;
  display: grid;
  place-items: center;
  position: absolute;
  inset-inline-end: -20px;
  inset-block-start: calc(50% - 20px);
  block-size: 40px;
  inline-size: 40px;
  border: 1px solid black;
  border-radius: 100%;
  background-color: white;
}
@container body (min-width: 600px) {
  .AppSideBar-module__AppSideBar__WJYzo {
    display: flex;
  }
}

/* css-modules-plugin-ns:app/routes/$location._c/LocationContainer.module.css?css-modules-plugin-compiled-css */
.LocationContainer-module__LocationContainer__cCZBW {
  block-size: 100cqb;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: clip;
  background-color: hsl(225 22% 96%);
  & > main {
    flex-grow: 1;
    block-size: 100%;
    overflow: clip;
  }
}

/* css-side-effects-plugin-ns:../../packages/ui/src/styles/reset.css */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
  cursor: revert;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
:where(table) {
  border-collapse: collapse;
}
:where(textarea) {
  white-space: revert;
}
:where(meter) {
  -webkit-appearance: revert;
  -moz-appearance: revert;
  appearance: revert;
}
:where(pre) {
  all: revert;
}
:where(::-moz-placeholder) {
  color: unset;
}
:where(::placeholder) {
  color: unset;
}
:where(::marker) {
  content: initial;
}
:where(ul, ol) {
  list-style: none;
}
:where(dialog:modal) {
  all: revert;
  margin: auto;
  padding: 0;
  max-inline-size: 100dvi;
  max-block-size: 100dvb;
}
:where([hidden]:not([hidden="until-found"])) {
  display: none !important;
}
:where(html) {
  -webkit-text-size-adjust: none;
}
@media (prefers-reduced-motion: no-preference) {
  :where(html:focus-within) {
    scroll-behavior: smooth;
  }
}
:where(body) {
  block-size: 100%;
  block-size: 100dvb;
  line-height: 1;
  font-family: system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
}
:where(input, button, textarea, select) {
  font: inherit;
  color: inherit;
}
:where(textarea) {
  resize: none;
}
:where(button, label, select, summary, [role="button"], [role="option"]) {
  cursor: pointer;
}
:where(label:has(> input:disabled), label:has(+ input:disabled)) {
  cursor: not-allowed;
}
:where(:disabled) {
  cursor: not-allowed;
}
:where(a) {
  text-underline-offset: 0.2ex;
}
:where(img, svg, video, canvas, audio, iframe, embed, object) {
  display: block;
}
:where(img, picture, svg) {
  max-inline-size: 100%;
  block-size: auto;
}
:where(p, h1, h2, h3, h4, h5, h6) {
  overflow-wrap: break-word;
}
:where(:focus-visible) {
  outline: 2px solid var(--focus-color, Highlight);
  outline-offset: 2px;
}
:where(.visually-hidden:not(:focus, :active, :focus-within, .not-visually-hidden)) {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap !important;
  border: 0 !important;
}

/* css-side-effects-plugin-ns:../../packages/ui/src/styles/global.css */
:root {
  --white-hsl-raw: 0 0% 100%;
  --white: hsl(var(--white-hsl-raw) / 100%);
  --white-40: hsl(var(--white-hsl-raw) / 40%);
  --light: hsl(225 22% 96% / 100%);
  --dark: hsl(271 55% 10% / 100%);
  --purple: hsl(252 58% 58% / 100%);
  --blue: hsl(230 67% 54% / 100%);
  --yellow: hsl(47 100% 69% / 100%);
  --gray2: hsl(223 8% 72% / 100%);
  --primary-color: var(--purple);
  --primary-button-color: var(--white);
  --primary-button-background-color: var(--primary-color);
  --disabled-button-color: var(--white-40);
  --disabled-button-background-color: var(--gray2);
}
.IconButton {
  font-family: inherit;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  inline-size: calc(24px + 12px);
  block-size: calc(24px + 12px);
  border: 1px solid black;
  border-radius: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  aspect-ratio: 1/1;
  background-color: #eee;
}
.capitalize {
  text-transform: capitalize;
}
label {
  text-transform: capitalize;
  & > *:not(:where(button, input[type="button"], input[type="submit"])) {
    text-transform: revert;
  }
}
* {
  &[data-capitalize=true] {
    text-transform: capitalize;
  }
  &[data-block-radius=start] {
    border-top-left-radius: var(--radius-size);
    border-top-right-radius: var(--radius-size);
  }
  &[data-block-radius=end] {
    border-bottom-left-radius: var(--radius-size);
    border-bottom-right-radius: var(--radius-size);
  }
  &[data-block-radius=both] {
    border-start-start-radius: var(--radius-size);
    border-start-end-radius: var(--radius-size);
    border-end-start-radius: var(--radius-size);
    border-end-end-radius: var(--radius-size);
  }
  &[data-inline-radius=start] {
    border-start-start-radius: var(--radius-size);
    border-end-start-radius: var(--radius-size);
  }
  &[data-inline-radius=end] {
    border-start-end-radius: var(--radius-size);
    border-end-end-radius: var(--radius-size);
  }
  &[data-inline-radius=both] {
    border-start-start-radius: var(--radius-size);
    border-end-start-radius: var(--radius-size);
    border-start-end-radius: var(--radius-size);
    border-end-end-radius: var(--radius-size);
  }
}

/* css-side-effects-plugin-ns:app/styles/global.css */
body {
  overflow: clip;
  container: body / inline-size;
}
.debug-block {
  border: 1px solid black;
  padding: 8px 16px;
}

/* css-side-effects-plugin-ns:app/styles/masonry.css */
.masonry {
  display: grid;
  grid-auto-rows: 1rem;
}
.C1 {
  grid-column: 1;
}
.C2 {
  grid-column: 2;
}
.C3 {
  grid-column: 3;
}
.R1 {
  grid-row-end: span 1;
}
.R2 {
  grid-row-end: span 2;
}
.R3 {
  grid-row-end: span 3;
}
.R4 {
  grid-row-end: span 4;
}
.R5 {
  grid-row-end: span 5;
}
.R6 {
  grid-row-end: span 6;
}
.R7 {
  grid-row-end: span 7;
}
.R8 {
  grid-row-end: span 8;
}
.R9 {
  grid-row-end: span 9;
}
.R10 {
  grid-row-end: span 10;
}
.RS1 {
  grid-row-start: 1;
}
.RS2 {
  grid-row-start: 2;
}
.RS3 {
  grid-row-start: 3;
}
.RS4 {
  grid-row-start: 4;
}
.RS5 {
  grid-row-start: 5;
}
.RS6 {
  grid-row-start: 6;
}
.RS7 {
  grid-row-start: 7;
}
.RS8 {
  grid-row-start: 8;
}
.RS9 {
  grid-row-start: 9;
}
.RS10 {
  grid-row-start: 10;
}
.RS11 {
  grid-row-start: 11;
}
.RS12 {
  grid-row-start: 12;
}
.RS13 {
  grid-row-start: 13;
}
.RS14 {
  grid-row-start: 14;
}
.RS15 {
  grid-row-start: 15;
}
.RS16 {
  grid-row-start: 16;
}
.RS17 {
  grid-row-start: 17;
}
.RS18 {
  grid-row-start: 18;
}
.RS19 {
  grid-row-start: 19;
}
.RS20 {
  grid-row-start: 20;
}
/*# sourceMappingURL=/build/css-bundle-ARHPANVW.css.map */
