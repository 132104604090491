.Events-module__Events__mjsOZ {
  position: relative;
}

.Events-module__Header__mvjr- {
  display: flex;
  justify-content: space-between;
}

.Events-module__Tabs__grXvG {
  display: flex;
  gap: 12px;
  & .Events-module__Active__gfM6b {
    border-block-end: 1px solid black;
  }
}

.Events-module__Content__5zqt- {
  display: flex;
  flex-wrap: wrap;
}
