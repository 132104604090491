.Location-module__Location__LyV96 {
  grid-template-columns: 1fr;
  place-items: stretch stretch;
  gap: 16px;
  padding: 24px;
  & h1 {
    color: hsl(247 21% 17%);
    font-size: 14px;
  }
}

@container body (min-width: 600px) {
  .Location-module__Location__LyV96 {
    grid-template-columns: 1fr 1fr;
  }

  .Location-module__Card__-cYAG:nth-child(odd) {
    grid-column: 1;
  }

  .Location-module__Card__-cYAG:nth-child(even) {
    grid-column: 2;
  }

  .Location-module__Card__-cYAG:nth-child(1) {
    grid-row: span 10;
  }

  .Location-module__Card__-cYAG:nth-child(3) {
    grid-row-end: span 4;
  }

  .Location-module__Card__-cYAG:nth-child(5) {
    grid-row: span 4;
  }

  .Location-module__Card__-cYAG:nth-child(7) {
    grid-row: span 8;
  }

  .Location-module__Card__-cYAG:nth-child(2) {
    grid-row: 1 / span 8;
  }

  .Location-module__Card__-cYAG:nth-child(4) {
    grid-row: 8 / span 4;
  }

  .Location-module__Card__-cYAG:nth-child(6) {
    grid-row: 12 / span 8;
  }
}

.Location-module__Card__-cYAG {
  inline-size: 100%;
}

.Location-module__PreviewButton__VqdLE {
  display: block;
  position: fixed;
  inset-inline-end: 0;
  inset-block-end: 50%;
  z-index: 2;
  border: 1px solid black;
  padding: 8px 16px;

  transform-origin: bottom right;
  transform: rotate(-90deg) translate(50%);
}
