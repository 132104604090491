.Users-module__Users__yMACD {
  position: relative;
}

.Users-module__Header__QIiGJ {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
  & > div {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
}

.Users-module__Footer__E2gnM {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
