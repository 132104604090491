.Coupons-module__Coupons__MXq0Z {
  position: relative;
}

.Coupons-module__Header__8yuND {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}

.Coupons-module__Footer__wxOL- {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Coupons-module__ToggleGroup__qRWUo {
  display: flex;
  gap: 12px;
}

.Coupons-module__ToggleGroupItem__VCaji {
  display: flex;
  gap: 4px;
}
