.AppHeader-module__AppHeader__D86OP {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  inline-size: 100%;
  border-bottom: 1px solid black;
  & .AppHeader-module__Heading__9LlRF {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }
}

.AppHeader-module__HeaderItem__P1R2Y {
  display: flex;
}

.AppHeader-module__HeaderMenu__p1RyT {
  position: relative;
}

.AppHeader-module__HeaderMenuViewPort__Evjkb {
  position: absolute;
}
