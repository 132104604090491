.Faqs-module__Faqs__1JptQ {
  position: relative;
}

.Faqs-module__Header__ST6LJ {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  flex-wrap: wrap;
}

.Faqs-module__Footer__EmaIq {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Faqs-module__ToggleGroup__Dm-Oy {
  display: flex;
  gap: 12px;
}

.Faqs-module__ToggleGroupItem__lAVr7 {
  display: flex;
  gap: 4px;
}
