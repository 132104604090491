.Settings-module__Settings__aSEcL {
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.Settings-module__C1__vbAcX {
  grid-column: 1;
}

.Settings-module__C2__28twW {
  grid-column: 2;
}

.Settings-module__R1__N6JGm {
  grid-row-end: span 1;
}
.Settings-module__R2__Ev1Yr {
  grid-row-end: span 2;
}
.Settings-module__R3__-ZCBL {
  grid-row-end: span 3;
}
.Settings-module__R4__mLIym {
  grid-row-end: span 4;
}
.Settings-module__R5__xuqiP {
  grid-row-end: span 5;
}
.Settings-module__R6__-zrJl {
  grid-row-end: span 6;
}
.Settings-module__R7__AASrm {
  grid-row-end: span 7;
}
.Settings-module__R8__pVr75 {
  grid-row-end: span 8;
}
.Settings-module__R9__z2fCO {
  grid-row-end: span 9;
}
.Settings-module__R10__pVCM5 {
  grid-row-end: span 10;
}

.Settings-module__RS1__M86Q2 {
  grid-row-start: 1;
}
.Settings-module__RS2__xIpb3 {
  grid-row-start: 2;
}
.Settings-module__RS3__-BKgt {
  grid-row-start: 3;
}
.Settings-module__RS4__f2tvQ {
  grid-row-start: 4;
}
.Settings-module__RS5__ssu11 {
  grid-row-start: 5;
}
.Settings-module__RS6__0VFwY {
  grid-row-start: 6;
}
.Settings-module__RS7__H-wHI {
  grid-row-start: 7;
}
.Settings-module__RS8__P4g4y {
  grid-row-start: 8;
}
.Settings-module__RS9__Hy3Sm {
  grid-row-start: 9;
}
.Settings-module__RS10__yeUkS {
  grid-row-start: 10;
}
.Settings-module__RS11__ksxcx {
  grid-row-start: 11;
}
.Settings-module__RS12__7wh-w {
  grid-row-start: 12;
}
.Settings-module__RS13__69uQa {
  grid-row-start: 13;
}
.Settings-module__RS14__6lEPS {
  grid-row-start: 14;
}
.Settings-module__RS15__h7-Vp {
  grid-row-start: 15;
}
.Settings-module__RS16__zq-y3 {
  grid-row-start: 16;
}
.Settings-module__RS17__LEz8s {
  grid-row-start: 17;
}
.Settings-module__RS18__DhtW6 {
  grid-row-start: 18;
}
.Settings-module__RS19__SjPwp {
  grid-row-start: 19;
}
.Settings-module__RS20__3gvAr {
  grid-row-start: 20;
}
