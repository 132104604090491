.Topics-module__Topics__icBMm {
  position: relative;
}

.Topics-module__Header__jvh6M {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}

.Topics-module__Footer__P-HA2 {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Topics-module__ToggleGroup__9RM5M {
  display: flex;
  gap: 12px;
}

.Topics-module__ToggleGroupItem__GeC58 {
  display: flex;
  gap: 4px;
}
