.masonry {
  display: grid;
  grid-auto-rows: 1rem;
}

.C1 {
  grid-column: 1;
}
.C2 {
  grid-column: 2;
}
.C3 {
  grid-column: 3;
}

.R1 {
  grid-row-end: span 1;
}
.R2 {
  grid-row-end: span 2;
}
.R3 {
  grid-row-end: span 3;
}
.R4 {
  grid-row-end: span 4;
}
.R5 {
  grid-row-end: span 5;
}
.R6 {
  grid-row-end: span 6;
}
.R7 {
  grid-row-end: span 7;
}
.R8 {
  grid-row-end: span 8;
}
.R9 {
  grid-row-end: span 9;
}
.R10 {
  grid-row-end: span 10;
}

.RS1 {
  grid-row-start: 1;
}
.RS2 {
  grid-row-start: 2;
}
.RS3 {
  grid-row-start: 3;
}
.RS4 {
  grid-row-start: 4;
}
.RS5 {
  grid-row-start: 5;
}
.RS6 {
  grid-row-start: 6;
}
.RS7 {
  grid-row-start: 7;
}
.RS8 {
  grid-row-start: 8;
}
.RS9 {
  grid-row-start: 9;
}
.RS10 {
  grid-row-start: 10;
}
.RS11 {
  grid-row-start: 11;
}
.RS12 {
  grid-row-start: 12;
}
.RS13 {
  grid-row-start: 13;
}
.RS14 {
  grid-row-start: 14;
}
.RS15 {
  grid-row-start: 15;
}
.RS16 {
  grid-row-start: 16;
}
.RS17 {
  grid-row-start: 17;
}
.RS18 {
  grid-row-start: 18;
}
.RS19 {
  grid-row-start: 19;
}
.RS20 {
  grid-row-start: 20;
}
