.Campaigns-module__Campaigns__7qQ4K {
  position: relative;
}

.Campaigns-module__Header__6lX-d {
  position: sticky;
  inset-block-start: 0;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}

.Campaigns-module__Footer__gBCOQ {
  position: sticky;
  inset-block-end: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Campaigns-module__ToggleGroup__hPp6n {
  display: flex;
  gap: 12px;
}

.Campaigns-module__ToggleGroupItem__9xj7N {
  display: flex;
  gap: 4px;
}
