.AppSideBar-module__AppSideBar__WJYzo {
  display: none;
  position: relative;
  border-inline-end: black solid 1px;
  block-size: 100dvb;
}
.AppSideBar-module__MinimizeButton__joz3m {
  z-index: 1;
  display: grid;
  place-items: center;
  position: absolute;
  /* z-index: 1; */
  inset-inline-end: -20px;
  inset-block-start: calc(50% - 20px);
  block-size: 40px;
  inline-size: 40px;
  border: 1px solid black;
  border-radius: 100%;
  background-color: white;
}

@container body (min-width: 600px) {
  .AppSideBar-module__AppSideBar__WJYzo {
    display: flex;
  }
}
